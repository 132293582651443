import determineAIVersion from 'src/utils/determine-ai-version';
import determineBackendUrl from 'src/utils/determine-backend-url';

export const parseQueryParams = (): {
  baseUrl: string;
  xFabricAiVersion: string;
} => {
  const searchParams = new URLSearchParams(window.location.search);
  const backend = searchParams.get('backend');
  const version = searchParams.get('version');
  const xFabricAiVersion = determineAIVersion(version);
  const baseUrl = determineBackendUrl(backend);

  return {
    baseUrl,
    xFabricAiVersion,
  };
};

export const getStreamingHeaders = (xFabricAiVersion: string): {
  'Content-Type': 'application/json',
  Accept: 'text/event-stream',
  Authorization: string;
  'x-fabric-tenant-id': string;
  'x-fabric-ai-version': string;
} => (
  {
    'Content-Type': 'application/json',
    Accept: 'text/event-stream',
    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    'x-fabric-tenant-id': sessionStorage.getItem('accountId'),
    'x-fabric-ai-version': xFabricAiVersion,
  }
);
