import axios from 'axios';
import determineAIVersion from 'src/utils/determine-ai-version';
import determineBackendUrl from 'src/utils/determine-backend-url';
import setupAxiosInterceptors from 'src/utils/setupAxiosInterceptors';

const commerceGPTAPI = axios.create({
  baseURL: process.env.COMMERCE_GPT_API,
});

setupAxiosInterceptors(commerceGPTAPI);

commerceGPTAPI.interceptors.request.use((config) => {
  const searchParams = new URLSearchParams(window.location.search);
  const backend = searchParams.get('backend');
  config.baseURL = determineBackendUrl(backend);
  const version = searchParams.get('version');
  config.headers['x-fabric-ai-version'] = determineAIVersion(version);
  return config;
});

export default commerceGPTAPI;
