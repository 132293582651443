import { handleRefreshTokenV2 } from '@teamfabric/copilot-utilities';
import {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

const MAX_RETRIES = 3;

interface RetryConfig extends AxiosRequestConfig {
  retries?: number;
}

/**
 * Reusable utility to configure request and response interceptors for a given Axios instance.
 *
 * - Adds Authorization and custom tenant ID headers to all requests.
 * - Handles `403` errors by refreshing tokens and retrying up to 3 times.
 * - Redirects to the login page on `401` errors.
 * - Forwards other errors as-is.
 *
 * @param {AxiosInstance} axiosInstance - The Axios instance to configure.
 * @returns {void} Mutates the Axios instance by adding interceptors.
 */
const setupAxiosInterceptors = (axiosInstance: AxiosInstance): void => {
  // Ensure all requests have relevant headers
  axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (!config.headers) {
      config.headers = {} as AxiosHeaders;
    }

    // Check if headers is an instance of AxiosHeaders or a plain object
    const headers =
      config.headers instanceof AxiosHeaders ? config.headers : new AxiosHeaders(config.headers);
    headers.set('Authorization', `Bearer ${sessionStorage.getItem('accessToken')}`);
    headers.set('x-fabric-tenant-id', sessionStorage.getItem('accountId'));

    config.headers = headers;
    return config;
  });

  // Response interceptor for error handling with token refresh
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response, // Forward successful responses
    async (error: AxiosError) => {
      const originalRequest = error.config as RetryConfig;

      if (error.response?.status === 403) {
        if (originalRequest.retries && originalRequest.retries >= MAX_RETRIES) {
          console.error('Max retries reached');
          window.location.href = '/auth/v2/login'; // Redirect to login page, TODO should put this route in copilot-utilities
          return Promise.reject(error);
        }

        const refreshStatus = await handleRefreshTokenV2() as string;
        if (refreshStatus === 'success') {
          originalRequest.retries = (originalRequest.retries || 0) + 1;
          originalRequest.headers.Authorization = `Bearer ${sessionStorage.getItem('accessToken')}`;
          return axiosInstance(originalRequest); // Retry the original request
        }
      } else if (error.response?.status === 401) {
        window.location.href = '/auth/v2/login'; // Redirect to login page, TODO should put this route in copilot-utilities
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );
};

export default setupAxiosInterceptors;
